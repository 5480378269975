import React from "react"
import { Box } from "ticeboxes"
import AfspraakButton from "./AfspraakButton"

interface Props {}

const AfspraakCta: React.FC<Props> = () => {
  return (
    <Box text-center>
      <h3>Ben je er nog niet helemaal uit of wil je meer informatie?</h3>
      <Box mt="4">
        <AfspraakButton text="Maak nu een afspraak!" />
      </Box>
    </Box>
  )
}

export default AfspraakCta
