import { graphql } from "gatsby"
import React from "react"
import { Box } from "ticeboxes"
import Layout from "../components/layout/Layout"
import { StoreQuery } from "../../graphql-types"
import { H1 } from "../components/general/Typography"
import ImageBackground from "../components/general/ImageBackground"
import { Col, Container, Row } from "reactstrap"
import ReactMarkdown from "react-markdown"
import BreadCrumbs from "../components/general/BreadCrumbs"
import Head from "../components/layout/Head"
import AfspraakCta from "../components/general/AfspraakCta"

interface Props {
  data: StoreQuery
}

export const query = graphql`
  query Store($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        meta_description
        background_image
        content
      }
    }
  }
`

const Store: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Head
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.meta_description}
      />
      <Layout>
        <ImageBackground src={data.markdownRemark.frontmatter.background_image}>
          <Container>
            <Row>
              <Col xs="12" style={{ overflow: "hidden" }}>
                <Box style={{ padding: "120px 0" }} text-center>
                  <H1>{data.markdownRemark.frontmatter.title}</H1>
                </Box>
              </Col>
            </Row>
          </Container>
        </ImageBackground>
        <Container>
          <Row>
            <Col
              xs="12"
              sm={{ size: 10, offset: 1 }}
              md={{ size: 8, offset: 2 }}
              style={{ overflow: "hidden" }}
            >
              <Box my="4">
                <BreadCrumbs
                  link="/winkel"
                  textBefore="Winkel"
                  textAfter={data.markdownRemark.frontmatter.title}
                />
              </Box>
              <Box my="5" pb="4" className="template-container">
                <ReactMarkdown allowDangerousHtml>
                  {data.markdownRemark.frontmatter.content}
                </ReactMarkdown>
              </Box>
              <Box pb="5" mb="5">
                <AfspraakCta />
              </Box>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Store
